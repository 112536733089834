import { useDispatch, useSelector } from "store";
import { getUser, getMetrics } from "services/user";
import { useEffect, useMemo, useState } from "react";
import { UserLimits } from "@alphafold/types";

const USER_LIMITS_KEY = "userLimits";

const useMetering = () => {
  const { user, metrics } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(true);
  const [limits, setLimits] = useState<UserLimits>();

  useEffect(() => {
    const userLimitsString = localStorage.getItem(USER_LIMITS_KEY);
    if (userLimitsString) {
      setLimits(JSON.parse(userLimitsString) as UserLimits);
    }
    setIsFetching(true);
    Promise.all([dispatch(getUser()), dispatch(getMetrics())]).finally(() =>
      setIsFetching(false)
    );
  }, []);

  useEffect(() => {
    if (user?.limits) {
      setLimits(user.limits);
      localStorage.setItem(USER_LIMITS_KEY, JSON.stringify(user.limits));
    }
  }, [user?.limits]);

  const remainingToday = useMemo(
    () => (limits?.dailyLimit || 0) - (metrics?.submittedJobsToday || 0),
    [user, metrics]
  );
  const remainingMonth = useMemo(
    () => (limits?.monthlyLimit || 20) - (metrics?.submittedJobsThisMonth || 0),
    [user, metrics]
  );

  const timePeriod = remainingToday > remainingMonth ? "Month" : "Today";

  const remainingPeriod = Math.max(Math.min(remainingToday, remainingMonth), 0);

  const limitPeriod =
    limits?.monthlyLimit == Infinity && limits?.dailyLimit == Infinity
      ? Infinity
      : remainingToday > remainingMonth
      ? limits?.monthlyLimit
      : limits?.dailyLimit;

  const submittedPeriod =
    remainingToday > remainingMonth
      ? metrics?.submittedJobsThisMonth
      : metrics?.submittedJobsToday;

  return {
    metrics,
    limits: limits,
    isFetching,
    period: {
      timePeriod,
      limit: limitPeriod,
      submitted: submittedPeriod,
      remaining: remainingPeriod,
    },
    remaining: {
      day: remainingToday,
      monh: remainingMonth,
      period: remainingMonth,
    },
  };
};

export { useMetering as default };
