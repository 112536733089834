import { authHeader } from "services/auth";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
  ? process.env.REACT_APP_API_ENDPOINT.replace(/\/+$/, "")
  : "";

export const REQUEST_OPTIONS: () => RequestInit = () => ({
  mode: "cors" as const, // no-cors, *cors, same-origin
  cache: "no-cache" as const, // *default, no-cache, reload, force-cache, only-if-cached
  credentials: "same-origin" as const, // include, *same-origin, omit
  headers: {
    "Content-Type": "application/json",
    ...authHeader(),
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
  redirect: "follow" as const, // manual, *follow, error
  referrerPolicy: "no-referrer" as const,
});

export const request = (
  path = "",
  method: string,
  data?: any,
  options?: any
) => {
  let init: RequestInit = {
    ...REQUEST_OPTIONS(),
    method,
  };
  if (data !== undefined) {
    init.body = JSON.stringify(data);
  }
  if (options !== undefined) {
    init = { ...init, ...options };
  }
  return fetch(`${apiEndpoint}/${path.replace(/^\//, "")}`, init);
};

export function get(path = "", params?: URLSearchParams) {
  const _path = params ? `${path}?${params}` : path;
  return request(_path, "GET").then(async (response) => {
    if (response.ok) {
      return response.json();
    } else if (response.body) {
      try {
        throw new Error((await response.json()).message);
      } catch {
        throw new Error(`${response.status.toString()} ${response.statusText}`);
      }
    } else {
      throw new Error(`${response.status.toString()} ${response.statusText}`);
    }
  });
}

export function del(path = "") {
  return request(path, "DELETE").then(async (response) => {
    if (response.ok) {
      try {
        const d = await response.json();
        return d;
      } catch {
        return true;
      }
    } else if (response.body) {
      throw new Error((await response.json()).message);
    } else {
      throw new Error(`${response.status.toString()} ${response.statusText}`);
    }
  });
}

export async function post(path = "", data = {}, options?: any) {
  const response = await request(path, "POST", data, options);
  console.log({ response });
  if (response.ok) {
    try {
      const d = await response.json();
      return d;
    } catch {
      return true;
    }
  } else if (response.body) {
    throw new Error((await response.json()).message);
  } else {
    throw new Error(`${response.status.toString()} ${response.statusText}`);
  }
}

export function put(path = "", data = {}, options?: any) {
  return request(path, "PUT", data, options).then(async (response) => {
    if (response.ok) {
      try {
        const d = await response.json();
        return d;
      } catch {
        return true;
      }
    } else if (response.body) {
      throw new Error((await response.json()).message);
    } else {
      throw new Error(`${response.status.toString()} ${response.statusText}`);
    }
  });
}
