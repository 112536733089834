/* eslint-disable prettier/prettier */
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  LdAccordion,
  LdAccordionPanel,
  LdAccordionSection,
  LdAccordionToggle,
  LdBadge,
  LdButton,
  LdCard,
  LdIcon,
  LdInput,
  LdInputMessage,
  LdLabel,
  LdNotice,
  LdOption,
  LdSelect,
  LdStep,
  LdStepper,
  LdToggle,
  LdTooltip,
  LdTypo,
} from "@emdgroup-liquid/liquid/dist/react";
import ReactMarkdown from "react-markdown";
//import { Code, vs2015 } from "react-code-blocks";

import assert from "assert";
import { useDispatch, useSelector } from "store";
import {
  cancelDraft,
  createJob,
  submitDraft,
  setUseSpotInstance,
  setAdvancedParameters,
  uploadInputFile,
  uploadTemplateFiles,
} from "services/draft";
import AppLayout from "layout/AppLayout";
import UploadDropzone from "components/UploadDropZone";

//import Card from "components/Card";
import FileInfo from "components/FileInfo";
import { AdvancedParametersLabels, FileStatus, StateStatus } from "types/app";
import { useNavigate } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import type {
  LdSelectCustomEvent,
  LdToggleCustomEvent,
} from "@emdgroup-liquid/liquid";
import UploadFileModal from "components/Modals/UploadFileModal";
import { templateFileValidator } from "util/files";
import { selectRemaining, selectUserLimits } from "services/user";

const steps = [
  {
    order: 0,
    label: "Name Your Job",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    order: 1,
    label: "Select Fasta File",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    order: 2,
    label: "Review",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const Upload: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const draft = useSelector((state) => state.draft);
  const { sequenceLength: sequenceLengthLimit = Infinity } = useSelector(
    selectUserLimits
  ) as any;
  const remainingJobs = useSelector(selectRemaining);
  // const [currentStep, setCurrentStep] = useState(0);
  const [jobName, setJobName] = useState<string>();
  const [modal, setModal] = useState<string>();
  // const [inputFile, setInputFile] = useState<File>();
  // const { status } = useSelector((state) => state.jobs);

  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: "New Job",
    });
  }, []);

  const currentStep = useMemo(() => {
    if (!draft.jobName) return 0;
    if (draft.inputFiles.length === 0) return 1;
    return 2;
  }, [draft]);

  const handleCreateJob = async () => {
    assert(jobName);
    trackEvent({
      category: "new-job",
      action: "enter-name",
      documentTitle: "New Job",
    });
    await dispatch(createJob({ jobName }));
    // setCurrentStep(1);
  };

  const handleDropFile = async (files: File[] | FileList) => {
    const file = files[0];
    if (!!file) {
      assert(draft.jobId);
      trackEvent({
        category: "new-job",
        action: "upload-file",
        documentTitle: "New Job",
      });
      dispatch(uploadInputFile({ jobId: draft.jobId, file }));
    }
  };

  const handleUploadTemplate = async (files: File[]) => {
    if (files.length > 0) {
      assert(draft.jobId);
      dispatch(uploadTemplateFiles({ jobId: draft.jobId, files }));
      setModal(undefined);
    }
  };

  const handleChangeSpotInstance = (e: LdToggleCustomEvent<boolean>) => {
    let newState = { ...draft.useSpotInstance };
    if (e.target.name === "useSpotInstance-cpu") {
      newState.cpu = e.detail;
    } else if (e.target.name === "useSpotInstance-gpu") {
      newState.gpu = e.detail;
    }
    dispatch(setUseSpotInstance(newState));
  };

  const handleChangeParameter = (e: LdSelectCustomEvent<string[]>) => {
    let newState = { ...draft.advancedParameters };
    if (e.target.name) {
      newState[e.target.name] = e.detail[0];
    }
    dispatch(setAdvancedParameters(newState));
  };

  const handleNext = () => {
    switch (currentStep) {
      case 0:
        handleCreateJob();
        break;
      case 2:
        handleSubmit();
        return;
      default:
        return;
    }
  };

  const handleCancel = () => {
    trackEvent({
      category: "new-job",
      action: "cancel-creation",
      documentTitle: "New Job",
    });
    dispatch(cancelDraft());
  };

  const handleSubmit = async () => {
    assert(draft.jobId);
    trackEvent({
      category: "new-job",
      action: "submit-job",
      documentTitle: "New Job",
    });
    dispatch(
      submitDraft({
        jobId: draft.jobId,
        useSpotInstance: draft.useSpotInstance,
        advancedParameters: draft.advancedParameters,
      })
    );
    navigate("/jobs");
  };

  const disabled = useMemo(() => {
    if (remainingJobs < 1) return true;
    switch (currentStep) {
      case 0:
        return !jobName;
      case 1:
        return draft.inputFiles.length < 1;
      case 2:
        for (let i = 0; i < draft.inputFiles.length; i++) {
          const { status } = draft.inputFiles[i];
          if (status !== FileStatus.valid) return true;
        }
        if (draft.templateFiles) {
          for (let i = 0; i < draft.templateFiles.length; i++) {
            const { status } = draft.templateFiles[i];
            if (status !== FileStatus.valid) return true;
          }
        }
        return false;
      default:
        return true;
    }
  }, [currentStep, draft, jobName, remainingJobs]);

  return (
    <AppLayout>
      <div
        id="upload"
        className="relative flex max-w-[1024px] mx-auto gap-ld-16 pt-ld-32 justify-center"
      >
        <div className="relative flex flex-col items-start w-full mt-ld-16 gap-ld-16 ">
          <LdNotice headline="Modelling Complexes" mode="info">
            <ReactMarkdown>
              Use `:` to specify inter-protein chainbreaks for modeling
              complexes (supports homo- and hetro-oligomers). For example:
              `PI...SK:PI...SK for a homodimer`
            </ReactMarkdown>
          </LdNotice>
          <LdCard className="flex flex-col w-full gap-ld-8 items-center">
            {remainingJobs < 1 && (
              <LdNotice headline="Limit Exceeded" mode="error">
                You exceeded the limit of submitted Jobs according to our fair
                use policy.
              </LdNotice>
            )}
            <div className="z-10">
              <LdStepper fit-content>
                {steps.map(({ label, order }) => (
                  <LdStep
                    key={order}
                    done={order < currentStep}
                    current={order == currentStep}
                  >
                    {label}
                  </LdStep>
                ))}
              </LdStepper>
            </div>
            <>
              <div className="flex flex-col w-full justify-start gap-ld-8">
                <LdLabel>
                  Job Name:
                  {!draft.jobName ? (
                    <LdInput
                      onInput={(ev) => {
                        let value = (ev.target as HTMLLdInputElement).value;
                        setJobName(value);
                      }}
                    />
                  ) : (
                    <LdTypo variant="h2">{draft.jobName}</LdTypo>
                  )}
                </LdLabel>
                {draft.inputFiles.length > 0 && (
                  <LdLabel>
                    Input:
                    {draft.inputFiles.map(({ status, ...file }) => (
                      <FileInfo
                        key={file.fileName}
                        file={file}
                        status={status}
                      />
                    ))}
                  </LdLabel>
                )}
                {draft.templateFiles && (
                  <LdLabel>
                    Templates:
                    {draft.templateFiles.map(({ status, ...file }) => (
                      <FileInfo
                        key={file.fileName}
                        file={file}
                        status={status}
                      />
                    ))}
                  </LdLabel>
                )}
                {currentStep === 2 && !draft.templateFiles && (
                  <div className="pt-ld-8">
                    <LdButton
                      size="sm"
                      mode="secondary"
                      onClick={() => setModal("template-modal")}
                    >
                      <LdIcon name="dna" /> Use Custom Template
                    </LdButton>
                  </div>
                )}
              </div>
              {currentStep === 1 && (
                <div>
                  <UploadDropzone
                    className="my-ld-16"
                    onDrop={(files: File[]) => handleDropFile(files)}
                  />
                  {sequenceLengthLimit > -1 && sequenceLengthLimit < Infinity && (
                    <LdInputMessage mode="info">
                      Sequences must have {sequenceLengthLimit} residues or less
                    </LdInputMessage>
                  )}
                </div>
              )}
              {currentStep === 2 && (
                <LdAccordion className="w-full my-ld-16" rounded tone="dark">
                  <LdAccordionSection>
                    <LdAccordionToggle>Advanced Options</LdAccordionToggle>
                    <LdAccordionPanel>
                      <div className="grid grid-cols-4">
                        {Object.keys(AdvancedParametersLabels).map((key) => (
                          <LdLabel className="p-ld-16 w-full" key={key}>
                            <span className="flex flex-row items-center gap-ld-4">
                              {AdvancedParametersLabels[key].label}
                              <LdTooltip size="sm">
                                {AdvancedParametersLabels[key].description}
                              </LdTooltip>
                            </span>
                            <LdSelect
                              name={key}
                              onLdchange={handleChangeParameter}
                            >
                              {AdvancedParametersLabels[key].values.map(
                                (val) => (
                                  <LdOption
                                    key={val}
                                    value={`${val}`}
                                    selected={
                                      val.toString() ===
                                      draft.advancedParameters[key]
                                    }
                                  >
                                    {val}
                                  </LdOption>
                                )
                              )}
                            </LdSelect>
                          </LdLabel>
                        ))}
                      </div>
                    </LdAccordionPanel>
                  </LdAccordionSection>
                  <LdAccordionSection>
                    <LdAccordionToggle>Compute Environment</LdAccordionToggle>
                    <LdAccordionPanel>
                      <LdLabel position="left" className="p-ld-16 w-full">
                        Use EC2 Spot Instances for MSA
                        <LdToggle
                          size="sm"
                          name="useSpotInstance-cpu"
                          onLdchange={handleChangeSpotInstance}
                          checked={draft.useSpotInstance.cpu}
                        />
                      </LdLabel>
                      <LdLabel position="left" className="p-ld-16 w-full">
                        Use EC2 Spot Instances for Prediction
                        <LdToggle
                          size="sm"
                          name="useSpotInstance-gpu"
                          onLdchange={handleChangeSpotInstance}
                          checked={draft.useSpotInstance.gpu}
                        />
                      </LdLabel>
                    </LdAccordionPanel>
                  </LdAccordionSection>
                </LdAccordion>
              )}
            </>
            <div className="w-full flex flex-row justify-end gap-ld-8">
              <LdButton onClick={handleCancel} mode="danger-secondary">
                Cancel
              </LdButton>
              <LdButton
                onClick={handleNext}
                disabled={disabled}
                progress={
                  draft.status === StateStatus.loading ? "pending" : undefined
                }
              >
                {currentStep === steps.length - 1 ? "Submit" : "Next"}
              </LdButton>
            </div>
          </LdCard>
        </div>
      </div>
      <UploadFileModal
        open={modal === "template-modal"}
        onCancel={() => setModal(undefined)}
        onUpload={handleUploadTemplate}
        multiple={true}
        validator={templateFileValidator}
        accept={{
          "text/plain": [".pdb"],
          "chemical/x-pdb": [".pdb"],
        }}
      >
        <LdTypo>
          <LdBadge icon="info" size="lg" className="mr-ld-4" />
          Templates must follow the four letter PDB naming with lower case
          letters.
        </LdTypo>
      </UploadFileModal>
    </AppLayout>
  );
};

export default Upload;
