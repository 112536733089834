import React, { FC } from "react";
import {
  LdAccordionPanel,
  LdAccordionSection,
  LdAccordionToggle,
  LdButton,
} from "@emdgroup-liquid/liquid/dist/react";
import {
  // eslint-disable-next-line import/named
  LdAccordionSectionCustomEvent,
} from "@emdgroup-liquid/liquid";
import { File, Job } from "@alphafold/types";
import { ComponentProps } from "types/app";

import Card from "components/Card";
import JobDetails from "pages/Job/components/JobDetails";

import { useMatomo } from "@jonkoops/matomo-tracker-react";
import ProteinList from "pages/Job/components/ProteinList";

interface JobListItemProps extends ComponentProps {
  job: Job;
  inputFiles?: File[];
  onClick?: (jobId: string) => void;
  onDeleteJob?: (jobId: string) => void;
}

const JobListItem: FC<JobListItemProps> = ({ job, onClick, onDeleteJob }) => {
  const { trackEvent } = useMatomo();

  const DOCUMENT_TITLE = "Job List";

  const handleToggleInputs = (
    event: LdAccordionSectionCustomEvent<boolean>
  ) => {
    event.stopPropagation();
    if (event.detail && job.jobId) {
      trackEvent({
        category: "job-details",
        action: "expand-input",
        name: job.jobId,
        documentTitle: DOCUMENT_TITLE,
        customDimensions: [
          {
            id: 1,
            value: "job-list",
          },
        ],
      });
    }
  };

  return (
    <Card
      className="w-full"
      onClick={() => job.jobId && onClick && onClick(job.jobId)}
    >
      <JobDetails
        job={job}
        accordionSections={
          job.proteins ? (
            <LdAccordionSection
              key="protein-List"
              onClick={(e) => e.stopPropagation()}
              onLdaccordionchange={handleToggleInputs}
            >
              <LdAccordionToggle>Proteins</LdAccordionToggle>
              <LdAccordionPanel>
                <div className="gap-4 px-ld-16 py-ld-8">
                  <ProteinList proteins={job.proteins} />
                </div>
              </LdAccordionPanel>
            </LdAccordionSection>
          ) : null
        }
      />
      <div className="flex w-full flex-row justify-end gap-ld-8">
        {onDeleteJob && (
          <LdButton
            size="sm"
            mode="danger"
            onClick={(e) => {
              e.stopPropagation();
              trackEvent({
                category: "job-details",
                action: "delete-job",
                name: job.jobId,
                documentTitle: DOCUMENT_TITLE,
                customDimensions: [
                  {
                    id: 1,
                    value: "job-list",
                  },
                ],
              });
              job.jobId && onDeleteJob(job.jobId);
            }}
          >
            Delete Job
          </LdButton>
        )}
      </div>
    </Card>
  );
};

export default JobListItem;
