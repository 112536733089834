/* eslint-disable import/named */
import { createAsyncThunk, createSlice, AnyAction } from "@reduxjs/toolkit";
import {
  assertUser,
  assertUserMetrics,
  User,
  UserLimits,
  UserMetrics,
} from "@alphafold/types";
import { StateStatus } from "types/app";
import { PendingAction, RejectedAction } from "store/store";
import { get, post, put } from "util/api";
import _ from "lodash";

export interface UserState {
  user?: User;
  metrics?: UserMetrics;
  status: StateStatus;
  error: string | null;
}

const initialState: UserState = {
  status: StateStatus.idle,
  error: null,
};

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith("/pending") && action.type.startsWith("user/");
}
function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith("/rejected") && action.type.startsWith("user/");
}

export const createUser = createAsyncThunk("user/createUser", async () => {
  const user = await post("user", {});
  assertUser(user);
  return user;
});

export const getUser = createAsyncThunk("user/getUser", async () => {
  const response = await get("user");
  assertUser(response);
  return response;
});

export const getMetrics = createAsyncThunk("user/getMetrics", async () => {
  const response = await get("metric");
  assertUserMetrics(response);
  return response;
});

export const changeSettings = createAsyncThunk(
  "user/changeSettings",
  async ({ enableNotifications }: { enableNotifications: boolean }) => {
    return put("preferences", { enableNotifications });
  }
);

export const selectRemaining = ({
  user: { metrics, user },
}: {
  user: UserState;
}) =>
  Math.min(
    (user?.limits?.dailyLimit || 0) - (metrics?.submittedJobsToday || 0),
    (user?.limits?.monthlyLimit || 0) - (metrics?.submittedJobsToday || 0)
  );

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      assertUser(payload);
      const user = payload;
      if (user.limits) {
        Object.keys(user.limits).forEach((key) => {
          if (user.limits![key as keyof UserLimits] === -1) {
            user.limits![key as keyof UserLimits] = Infinity;
          }
        });
      }
      state.user = payload;
    });
    builder.addCase(getMetrics.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      assertUserMetrics(payload);
      state.metrics = payload;
    });
    builder.addCase(changeSettings.pending, (state, { meta }) => {
      state.user = {
        ...state.user,
        ..._.pickBy(meta.arg, (value) => value !== undefined),
      } as User;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      assertUser(payload);
      state.user = payload;
    });
    builder
      .addMatcher(isPendingAction, (state) => {
        state.status = StateStatus.loading;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.status = StateStatus.failed;
        state.error = (action.error as Error).message;
      });
  },
});

export const selectUserLimits = ({ user: { user } }: { user: UserState }) =>
  user?.limits || {};

export default userSlice.reducer;
