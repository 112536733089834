/* eslint-disable import/named */
import {
  createAsyncThunk,
  createSlice,
  AnyAction,
  PayloadAction,
  isAnyOf,
  isAllOf,
} from "@reduxjs/toolkit";
import { File, isFile, isJob, Job } from "@alphafold/types";
import { del, get, post } from "util/api";
import { StateStatus } from "types/app";
import assert from "assert";
import _ from "lodash";
import { PendingAction, RejectedAction } from "store/store";
import { filterByTerm } from "util/search";
import { submitDraft } from "./draft";
import {
  extractImageInformation,
  extractUniqueResultFileInfo,
} from "util/files";

export interface JobsState {
  jobs: { [jobId: string]: Job };
  inputFiles: { [jobId: string]: File[] };
  templateFiles: { [jobId: string]: File[] };
  resultFiles: { [jobId: string]: Record<string, File[]> };
  resultImages: { [jobId: string]: Record<string, File[]> };
  resultArchive: { [jobId: string]: File };
  searchQuery?: string;
  sortBy: string;
  order: "asc" | "desc";
  status: StateStatus;
  error: string | null;
}

export const initialState: JobsState = {
  jobs: {},
  inputFiles: {},
  templateFiles: {},
  resultFiles: {},
  resultImages: {},
  resultArchive: {},
  sortBy: "createdAt",
  order: "desc",
  status: StateStatus.idle,
  error: null,
};

export const listJobs = createAsyncThunk("jobs/listJobs", async () => {
  return get("job");
});

export const refreshJobs = createAsyncThunk("jobs/refreshJobs", async () => {
  return get("job");
});

export const listInputFiles = createAsyncThunk(
  "jobs/listInputFiles",
  async (jobId: string) => {
    return get(`job/${jobId}/input`);
  }
);

export const listTemplateFiles = createAsyncThunk(
  "jobs/listTemplateFiles",
  async (jobId: string) => {
    return get(`job/${jobId}/input?fileType=template`);
  }
);

export const listResultFiles = createAsyncThunk(
  "jobs/listResultFiles",
  async ({
    jobId,
    sequenceName,
    sequenceIndex,
  }: {
    jobId: string;
    sequenceName?: string;
    sequenceIndex?: string;
  }) => {
    let params: URLSearchParams | undefined = undefined;
    if (sequenceName && sequenceIndex) {
      params = new URLSearchParams({ sequenceName, sequenceIndex });
    } else if (sequenceName) {
      params = new URLSearchParams({ sequenceName });
    } else if (sequenceIndex) {
      params = new URLSearchParams({ sequenceIndex });
    }
    const data = await get(`job/${jobId}/result`, params);
    return (data as File[]).reduce((prev, curr) => {
      const info = extractUniqueResultFileInfo(`${curr.fileName}`);
      if (info) {
        const existing = prev[info.sequenceName] || [];
        existing.push(curr);
        return { ...prev, [info.sequenceName]: existing };
      }
      return prev;
    }, {} as Record<string, File[]>);
  }
);

export const listResultImages = createAsyncThunk(
  "jobs/listResultImages",
  async ({
    jobId,
    sequenceName,
    sequenceIndex,
  }: {
    jobId: string;
    sequenceName?: string;
    sequenceIndex?: string;
  }) => {
    let params: URLSearchParams | undefined = undefined;
    if (sequenceName && sequenceIndex) {
      params = new URLSearchParams({ sequenceName, sequenceIndex });
    } else if (sequenceName) {
      params = new URLSearchParams({ sequenceName });
    } else if (sequenceIndex) {
      params = new URLSearchParams({ sequenceIndex });
    }
    const data = await get(`job/${jobId}/result-images`, params);
    return (data as File[]).reduce((prev, curr) => {
      const info = extractImageInformation(`${curr.fileName}`);
      if (info) {
        const existing = prev[info.sequenceName] || [];
        existing.push(curr);
        return { ...prev, [info.sequenceName]: existing };
      }
      return prev;
    }, {} as Record<string, File[]>);
  }
);

export const getResultArchive = createAsyncThunk(
  "jobs/getResultArchive",
  async (jobId: string) => {
    return get(`job/${jobId}/result-archive`);
  }
);

export const createJob = createAsyncThunk(
  "jobs/createJob",
  async (body: { jobName: string }) => {
    const data = await post("job", body);
    return data;
  }
);

export const deleteJob = createAsyncThunk("jobs/deleteJob", (jobId: string) => {
  return del(`job/${jobId}`);
});

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith("/pending") && action.type.startsWith("jobs/");
}
function isNotRefreshing(action: AnyAction): action is PendingAction {
  return !action.type.startsWith("jobs/refreshJobs");
}
function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith("/rejected") && action.type.startsWith("jobs/");
}

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    changeOrder: (state, action: PayloadAction<"asc" | "desc">) => {
      state.order = action.payload;
    },
    setQuery: (state, action: PayloadAction<string | undefined>) => {
      state.searchQuery = action.payload;
    },
    setSortyBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(listJobs.fulfilled, (state, { payload }) => {
    //   assert(Array.isArray(payload));
    //   assert(payload.map(isJob));
    //   state.status = StateStatus.succeeded;
    //   Object.assign(
    //     state.jobs,
    //     ...payload.map((job) => ({
    //       [job.jobId!]: job,
    //     }))
    //   );
    // });
    builder.addCase(listInputFiles.fulfilled, (state, { payload, meta }) => {
      assert(Array.isArray(payload));
      assert(payload.map(isFile));
      state.status = StateStatus.succeeded;
      state.inputFiles[meta.arg] = payload;
    });
    builder.addCase(listTemplateFiles.fulfilled, (state, { payload, meta }) => {
      assert(Array.isArray(payload));
      assert(payload.map(isFile));
      state.status = StateStatus.succeeded;
      state.templateFiles[meta.arg] = payload;
    });
    builder.addCase(listResultFiles.fulfilled, (state, { payload, meta }) => {
      //assert(Array.isArray(payload));
      assert(_.flatMap(Object.values(payload)).map(isFile));
      state.status = StateStatus.succeeded;
      // state.resultFiles[meta.arg.jobId] = payload;
      if (!state.resultFiles[meta.arg.jobId]) {
        state.resultFiles[meta.arg.jobId] = payload;
      } else {
        Object.assign(state.resultFiles[meta.arg.jobId], payload);
      }
    });
    builder.addCase(listResultImages.fulfilled, (state, { payload, meta }) => {
      assert(_.flatMap(Object.values(payload)).map(isFile));
      state.status = StateStatus.succeeded;
      //state.resultImages[meta.arg.jobId] = payload;
      if (!state.resultImages[meta.arg.jobId]) {
        state.resultImages[meta.arg.jobId] = payload;
      } else {
        Object.assign(state.resultImages[meta.arg.jobId], payload);
      }
    });
    builder.addCase(getResultArchive.fulfilled, (state, { payload, meta }) => {
      assert(isFile(payload));
      state.status = StateStatus.succeeded;
      state.resultArchive[meta.arg] = payload;
    });
    builder.addCase(createJob.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      assert(isJob(payload));
      const job = payload as Job;
      state.jobs[job.jobId!] = job;
    });
    builder.addCase(submitDraft.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      assert(isJob(payload));
      assert(payload.jobId);
      state.jobs[payload.jobId] = payload;
    });
    builder.addCase(deleteJob.pending, (state, { meta }) => {
      state.status = StateStatus.loading;
      delete state.jobs[meta.arg];
    });
    builder.addCase(deleteJob.fulfilled, (state, { meta }) => {
      state.status = StateStatus.succeeded;
      dispatchEvent(
        new CustomEvent("ldNotificationAdd", {
          detail: {
            content: `Job with id "${meta.arg}" has been deleted`,
            type: "info",
          },
        })
      );
    });
    builder
      .addMatcher(
        isAnyOf(listJobs.fulfilled, refreshJobs.fulfilled),
        (state, { payload }) => {
          assert(Array.isArray(payload));
          assert(payload.map(isJob));
          state.status = StateStatus.succeeded;
          // Removed deleted Jobs
          const deletedIds = _.difference(
            Object.keys(state.jobs),
            payload.map(({ jobId }) => jobId)
          );
          deletedIds.forEach((jobId) => {
            delete state.jobs[jobId];
          });

          payload.forEach((job: Job) => {
            if (state.jobs[job.jobId!] !== undefined) {
              Object.assign(state.jobs[job.jobId!], job);
            } else {
              state.jobs[job.jobId!] = job;
            }
          });
        }
      )
      .addMatcher(isAllOf(isPendingAction, isNotRefreshing), (state) => {
        state.status = StateStatus.loading;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.status = StateStatus.failed;
        state.error = (action.error as Error).message;
      });
  },
});

export const selectJobList = ({
  jobs: { jobs, searchQuery, sortBy, order },
}: {
  jobs: JobsState;
}) =>
  _.orderBy(
    Object.values(jobs).filter(
      ({ jobName }) =>
        !searchQuery || (jobName && filterByTerm(jobName, searchQuery))
    ),
    [sortBy],
    [order]
  );

export const selectJob =
  (jobId: string) =>
  ({ jobs: { jobs } }: { jobs: JobsState }) =>
    jobs[jobId] || {};

export const selectInputFiles =
  ({ jobs: { inputFiles } }: { jobs: JobsState }) =>
  (jobId: string) =>
    inputFiles[jobId] || [];

export const selectTemplateFiles =
  ({ jobs: { templateFiles } }: { jobs: JobsState }) =>
  (jobId: string) =>
    templateFiles[jobId] || [];

export const selectResultFiles =
  ({ jobs: { resultFiles } }: { jobs: JobsState }) =>
  (jobId: string) =>
    resultFiles[jobId] || {};

export const selectResultImages =
  ({ jobs: { resultImages } }: { jobs: JobsState }) =>
  (jobId: string) =>
    resultImages[jobId] || [];

export const selectResultArchive =
  ({ jobs: { resultArchive } }: { jobs: JobsState }) =>
  (jobId: string) =>
    resultArchive[jobId];

export const { setSortyBy, changeOrder, setQuery } = jobsSlice.actions;

export default jobsSlice.reducer;
