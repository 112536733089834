import React, { ReactNode } from "react";
import moment from "moment";
import {
  LdAccordion,
  LdAccordionPanel,
  LdAccordionSection,
  LdAccordionToggle,
  LdTooltip,
  LdTypo,
} from "@emdgroup-liquid/liquid/dist/react";
import { Job } from "@alphafold/types";
import { JobStatusBadge } from "components/Badge";
import { AdvancedParametersLabels, ComponentProps } from "types/app";
import useStatusTime from "../hooks/useStatusTime";

interface Props extends ComponentProps {
  job: Job;
  accordionSections?: ReactNode;
}

const JobDetails: React.FC<Props> = ({ job, accordionSections }) => {
  const { changedTimeString } = useStatusTime(job);
  return (
    <div className="flex flex-col justify-between w-full gap-4">
      <div className="flex flex-row justify-between">
        <LdTypo variant="h3">{job.jobName}</LdTypo>
        <LdTooltip arrow size="sm">
          <JobStatusBadge status={job.status} slot="trigger" />
          <LdTypo variant="body-xs">{job.status}</LdTypo>
        </LdTooltip>
      </div>
      <LdTypo variant="body-xs" className="mt-[-1rem]">
        {moment(job.createdAt).format("L LT")}
      </LdTypo>
      <LdTypo variant="body-xs" className="mt-[-1rem]">
        {changedTimeString}
      </LdTypo>
      {job.advancedParameters !== undefined && (
        <LdAccordion className="w-full my-ld-16" tone="dark" rounded>
          <LdAccordionSection onClick={(e) => e.stopPropagation()}>
            <LdAccordionToggle>Advanced Parameters</LdAccordionToggle>
            <LdAccordionPanel>
              <div className="flex flex-col gap-ld-4 px-ld-16 py-ld-8">
                {Object.keys(job.advancedParameters).map((key) => (
                  <LdTypo className="flex" variant="label-s" key={key}>
                    {AdvancedParametersLabels[key].label}:{" "}
                    <span className="font-normal">
                      {(job.advancedParameters as any)[key]}
                    </span>
                  </LdTypo>
                ))}
              </div>
            </LdAccordionPanel>
          </LdAccordionSection>
          {accordionSections}
        </LdAccordion>
      )}
    </div>
  );
};

export default JobDetails;
