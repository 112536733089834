import {
  LdButton,
  LdIcon,
  LdTable,
  LdTableBody,
  LdTableCell,
  LdTableHead,
  LdTableHeader,
  LdTableRow,
} from "@emdgroup-liquid/liquid/dist/react";

import { File, Protein } from "@alphafold/types";
import { extractUniqueResultFileInfo } from "util/files";

interface Props {
  files: File[];
  proteins?: Protein[];
  on3dView: (file: File) => void;
}

const TableCells = ({
  file,
}: // proteinName,
{
  file: File;
  // proteinName?: string;
}) => {
  const result = extractUniqueResultFileInfo(file.fileName || "");
  if (!result) {
    return (
      <LdTableCell className="py-ld-16" colspan={5}>
        {file.fileName}
      </LdTableCell>
    );
  }
  const { rank, model, relaxation } = result;
  return (
    <>
      <LdTableCell className="py-ld-16">{rank}</LdTableCell>
      <LdTableCell className="py-ld-16">{model}</LdTableCell>
      {/* <LdTableCell className="py-ld-16">{proteinName || ""}</LdTableCell> */}

      <LdTableCell className="py-ld-16">{relaxation}</LdTableCell>
    </>
  );
};

const ResultsList = ({ files, on3dView }: Props) => {
  // const findProteinName = useCallback(
  //   (fileName: string) => {
  //     const match = proteins.find(
  //       ({ proteinName }) =>
  //         proteinName && fileName.startsWith(sanatizeString(proteinName))
  //     );
  //     return match?.proteinName || fileName;
  //   },
  //   [proteins]
  // );

  return (
    <LdTable className="flex w-full flex-col divide-y divide-sensitive-grey-darker">
      <LdTableHead>
        <LdTableHeader className="py-ld-16" sortable sortOrder="desc">
          Rank
        </LdTableHeader>
        <LdTableHeader className="py-ld-16" sortable sortOrder="desc">
          Model
        </LdTableHeader>
        {/* <LdTableHeader className="py-ld-16">Name</LdTableHeader> */}
        <LdTableHeader className="py-ld-16" sortable sortOrder="desc">
          Relaxation
        </LdTableHeader>
        <LdTableHeader className="py-ld-16"></LdTableHeader>
      </LdTableHead>
      <LdTableBody className="divide-y divide-sensitive-grey-darkest">
        {files &&
          files.map((file) => {
            return (
              <LdTableRow key={file.downloadUrl}>
                <TableCells
                  file={file}
                  // proteinName={findProteinName(file.fileName || "")}
                />

                <LdTableCell className="flex justify-end sticky flex-grow">
                  <LdButton
                    onClick={() => on3dView(file)}
                    size="sm"
                    mode="ghost"
                  >
                    <LdIcon name="3d" />
                    View in 3D
                  </LdButton>
                  <LdButton
                    mode="ghost"
                    size="sm"
                    href={file.downloadUrl}
                    target="_blank"
                  >
                    <LdIcon name="download" />
                    Download
                  </LdButton>
                </LdTableCell>
              </LdTableRow>
            );
          })}
      </LdTableBody>
    </LdTable>
  );
};

export default ResultsList;
