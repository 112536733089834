import { File as AFFile } from "@alphafold/types";

export function formatFileSize(bytes: number | string, decimalPoint: number) {
  const bytesInt = typeof bytes === "string" ? parseInt(bytes) : bytes;
  if (bytesInt == 0) return "0 Bytes";
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytesInt) / Math.log(k));
  return parseFloat((bytesInt / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function downloadFile(url: string, fileName?: string) {
  var link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    link.setAttribute("href", url);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", fileName || "download");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
export function getFileExtension(fileName: string) {
  return fileName.split(".").pop();
}

export function extractUniqueResultFileInfo(fileName: string): {
  sequenceName: string;
  relaxation: string;
  rank: number;
  model: number;
  seed: number;
  ptm: boolean;
} | null {
  const regex =
    /(?<sequenceName>.+)_(?<relaxation>unrelaxed|relaxed)_(rank_(?<rank>\d+))(_[a-zA-Z0-9]+)+_(model_(?<model>\d+))_(seed_(?<seed>\d+)).pdb/gm;
  const match = regex.exec(fileName);

  if (match && match.groups) {
    const { sequenceName, relaxation, rank, model, seed, ptm } = match.groups;
    return {
      sequenceName,
      relaxation,
      rank: parseInt(rank, 10),
      model: parseInt(model, 10),
      seed: parseInt(seed, 10),
      ptm: !!ptm, // Convert to boolean
    };
  }

  return null; // Return null if no match is found
}

export function extractRankAndModel(fileName: string): {
  rank: number | null;
  model: number | null;
} {
  const rankMatch = fileName.match(/rank_(\d+)/);
  const modelMatch = fileName.match(/model_(\d+)/);

  const rank = rankMatch ? parseInt(rankMatch[1], 10) : null;
  const model = modelMatch ? parseInt(modelMatch[1], 10) : null;

  return { rank, model };
}

export function extractImageInformation(fileName: string):
  | {
      sequenceName: string;
      type: string;
    }
  | undefined {
  const regex = /(?<sequenceName>.+)_(?<type>pae|coverage|plddt)\.png/gm;
  const match = regex.exec(fileName);

  if (match && match.groups) {
    const { sequenceName, type } = match.groups;
    return {
      sequenceName,
      type,
    };
  }
}

export function getFileLabel(file: AFFile) {
  let label = undefined;
  if (!file.fileName) return label;
  const extension = getFileExtension(file.fileName);
  switch (extension) {
    case "pdb":
      const { rank, model } = extractRankAndModel(file.fileName);
      label = `Model ${model} (Rank ${rank})`;
      break;
    case "zip":
      label = "Full results";
  }
  return label;
}

export async function blobToBase64(
  blob: Blob,
  keepDeclaration = false
): Promise<string> {
  const regex = /data:.+\/.+;base64,/gm;

  const b: string = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
  if (keepDeclaration) {
    return b;
  }
  return b.replace(regex, "");
}

export function sanatizeString(string: string | undefined) {
  return `${string}`.replace(/[^\w\.-]/gm, "_");
}

export function isValidPDBFileName(fileName: string): boolean {
  // Regular expression to match the four-letter PDB naming convention
  const pdbRegex = /^[a-z0-9]{4}\.pdb$/;
  return pdbRegex.test(fileName);
}

export function templateFileValidator(file: File) {
  if (!isValidPDBFileName(file.name)) {
    return {
      code: "invalid-file-name",
      message:
        "Templates must follow the four letter PDB naming with lower case letters",
    };
  }

  return null;
}
