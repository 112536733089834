import {
  LdIcon,
  LdProgress,
  LdTooltip,
  LdTypo,
} from "@emdgroup-liquid/liquid/dist/react";

import useMetering from "hooks/useMetering";

const UserMetrics = () => {
  const { metrics, limits, period, isFetching } = useMetering();

  if (period.limit === Infinity || (isFetching && !metrics)) {
    return null;
  }

  return (
    <LdTooltip
      className="flex flex-col w-full"
      size="sm"
      position="right middle"
    >
      <LdTypo>
        Submitted today: {metrics?.submittedJobsToday}
        {limits?.dailyLimit !== Infinity && `/${limits?.dailyLimit}`} <br />
        Submitted this month: {metrics?.submittedJobsThisMonth}
        {limits?.monthlyLimit !== Infinity && `/${limits?.monthlyLimit}`}
      </LdTypo>
      <div className="flex flex-col w-full gap-ld-8 items-start" slot="trigger">
        <LdTypo variant="label-s" className="text-white align-middle">
          <LdIcon name="finance" size="sm" />{" "}
          {period.remaining !== Infinity ? period.remaining : "Unlimited"} Job
          {period.remaining !== 1 && "s"} left {period.timePeriod}
        </LdTypo>
        <LdProgress
          pending={isFetching}
          ariaValuenow={period.submitted}
          ariaValuemax={period.limit}
        />
      </div>
    </LdTooltip>
  );
};

export default UserMetrics;
