// Import the CryptoJS library
import * as CryptoJS from "crypto-js";

/**
 * Encrypts a string to an MD5 hash.
 * @param input - The string to be hashed.
 * @returns The MD5 hash of the input string.
 */

export function encryptToMD5(input: string): string {
  return CryptoJS.MD5(input).toString(CryptoJS.enc.Hex);
}

export function sha256(arr: string | Uint8Array): Promise<ArrayBuffer> {
  return crypto.subtle.digest(
    "SHA-256",
    typeof arr === "string" ? new TextEncoder().encode(arr) : arr
  );
}

export function base64encode(str: ArrayBuffer): string {
  let binary = "";
  const bytes = new Uint8Array(str);
  const len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window
    .btoa(binary)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}
