import { Job } from "@alphafold/types";
import React, { FC } from "react";
import { ComponentProps } from "types/app";
import JobListItem from "./JobListItem";

interface JobListProps extends ComponentProps {
  jobs: Job[];
  onClick?: (jobId: string) => void;
  onDeleteJob?: (jobId: string) => void;
}

const JobList: FC<JobListProps> = ({ jobs, onClick, onDeleteJob }) => {
  return (
    <div className="flex gap-4 flex-col items-center justify-start w-full px-ld-8 pb-ld-8 overflow-y-auto">
      {jobs.map((job) => (
        <JobListItem
          key={job.jobId}
          job={job}
          onClick={onClick}
          onDeleteJob={onDeleteJob}
        />
      ))}
    </div>
  );
};

export default JobList;
