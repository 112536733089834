import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import type { Accept, FileError, FileRejection } from "react-dropzone";
import { ReactComponent as DNAIcon } from "assets/static/images/dna.svg";

import "./styles.scss";

interface Props {
  multiple?: boolean;
  onDrop: (files: File[]) => void;
  onReject?: (files: FileRejection[]) => void;
  className?: string;
  accept?: Accept;
  validator?: <T extends File>(file: T) => FileError | FileError[] | null;
}

const UploadDropzone: React.FC<Props> = ({
  onDrop,
  onReject,
  multiple = false,
  className,
  accept,
  validator,
}) => {
  const handleDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      onDrop(acceptedFiles);
      onReject && onReject(rejectedFiles);
    },
    []
  );

  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple,
    accept,
    validator,
    noClick: false,
  });

  return (
    <div
      className={`drop-zone drop-zone-wrapper ${
        isDragActive && "dragging"
      } ${className}`}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} type="file" />
        <label className="upload__drop">
          <div
            data-test="upload-dropzone-label"
            className="flex flex-col items-center p-ld-16 text-center cursor-pointer"
          >
            <DNAIcon className="mb-3 stroke-current text-rich-blue-darkest w-12 h-12" />
            <p>
              Please drag & drop your file
              {multiple ? "s" : ""} into the window{" "}
              <span className="upload__drop-content-link">
                or click to select {multiple ? "" : "a "}file
                {multiple ? "s" : ""}.
              </span>
            </p>
          </div>
        </label>
      </div>
    </div>
  );
};

export default UploadDropzone;
